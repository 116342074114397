.header {
  position: fixed;
  height: 70px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
  color: #ffffff;
}

.color-nav-mobile {
  color: #ffffff;
}

.header-bg {
  background-color: #203849;
}
.font-header {
  color: #203849;
}

.font-header-2 {
  color: #203849;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

/* .header .nav-menu a {
  
} */

.header .navbar img {
  width: 75px;
  height: auto;
}

.header .nav-menu {
  display: flex;
}

.header .nav-item {
  padding: 1rem;
  font-weight: 500;
}

.header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header {
    max-width: 100%;
    color: #ffffff;
  }
  .header-bg {
    background-color: #203849;
  }

  .header .navbar {
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 70px;
    flex-direction: column;
    background-color: #203849;
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    width: 50px;
  }
}
